@import "rsuite/dist/rsuite.css";
@import "react-confirm-alert/src/react-confirm-alert.css";
@import "react-virtualized/styles.css";
@import "react-virtualized-select/styles.css";
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 1025px) {
  .h-custom-2 {
    height: 100%;
  }
}

.btn-get-danger {
  color: var(--default-color);
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 2px 10px;
  border-radius: 4px;
  transition: 0.5s;
  background-color: #dd3e4e;
  border: 2px solid color-mix(in srgb, var(--default-color), transparent 50%);
}
.login-body {
  /* background-color: #0157a5 !important; */
  margin-top: 200px !important;
  background-image: url("../public/assets/images/login_back.jpg");
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}

.navbar-nav a {
  color: rgb(214, 209, 209) !important;
  padding-left: 20px !important;
  font-size: 18px !important;
  text-decoration: none;
}

.navbar-nav .active {
  color: rgb(254, 254, 254) !important;
  font-size: 20px !important;
}

.Select-input {
  z-index: 100 !important;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30"%3e%3cpath stroke="rgba%280, 0, 0, 0.5%29" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/%3e%3c/svg%3e') !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  border-color: rgb(248, 244, 246) !important;
}

button.navbar-toggler {
  background-color: white;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #dceaf7;
  color: rgb(21, 21, 21);
}

.rs-input-group.rs-input-group-inside {
  height: 44px;
}

.login-back {
  background: url("../public/assets/images/login_back.jpg") no-repeat;
  height: 700px;
  background-size: cover;
}

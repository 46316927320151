@font-face {
  font-family: "Gill_Light"; /*Can be any text*/
  src: local("Gill_Light"), url("fonts/Hind-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  /* font-family: "Gill_Light" !important; */
 font-family: "Roboto, sans-serif";
  /* background-color: rgb(238, 237, 236) !important ; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: 75% !important;

}

.bg-grey {
  background-color: #e8e8e8 !important ;
}
.bg-light-blue {
  background-color: #dceaf7 !important ;
}
.h-100 {
  height: "100px !important";
}
code {
  font-family: "Courier New", monospace;
}
